function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { makeStyles } from "@material-ui/core";
export var useDetailStyles = makeStyles(function (theme) {
  var _itemContainer;

  return {
    root: {},
    image: _defineProperty({
      width: "100%",
      margin: theme.spacing(17, 0, 0)
    }, theme.breakpoints.down("xs"), {
      margin: theme.spacing(6, 0, 0)
    }),
    itemContainer: (_itemContainer = {
      padding: theme.spacing(4)
    }, _defineProperty(_itemContainer, theme.breakpoints.down("sm"), {
      padding: theme.spacing(2)
    }), _defineProperty(_itemContainer, theme.breakpoints.down("xs"), {
      margin: theme.spacing(-5, 0, 0)
    }), _itemContainer),
    titleContainer: {},
    title: _defineProperty({
      fontFamily: "Libre Baskerville",
      fontSize: "3.75rem",
      margin: 0,
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0em",
      color: theme.palette.primary.main,
      whiteSpace: "nowrap"
    }, theme.breakpoints.only("sm"), {
      fontSize: "3.50rem"
    }),
    descriptionContainer: _defineProperty({
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    }, theme.breakpoints.down("xs"), {
      alignItems: "center"
    }),
    description: _defineProperty({}, theme.breakpoints.down("xs"), {
      margin: theme.spacing(2, 0, 4),
      width: "96%"
    }),
    aboutTag: {
      fontFamily: "EB Garamond",
      fontSize: "1.5rem",
      margin: 0,
      fontStyle: "italic",
      fontWeight: "bold",
      lineHeight: "0.5rem",
      color: theme.palette.primary.main,
      padding: theme.spacing(0, 0, 0, 0.5)
    }
  };
});